:root {
   --logo-width: 60%;

}

@media (max-width: 750px) {
   :root {
      --logo-width: 90%;
   }
}

.OldEnglish {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   background-color: transparent;
   width: 100%;
   margin: 0;
   padding: 0;
   min-height: 100vh;
   /* border: 2px solid pink; */
}

.beforeTokenRender {
   /* border: 2px solid white; */
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   width: 100%;
}

.logoWidth {
   /* width: 60%; */
   width: var(--logo-width);
}

.ownershipFilterWrapper {
   width: 100%;
   margin-top: 2%;
   margin-left: 5%;
   margin-right: 5%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   gap: 1%; 
   justify-content: center;
/*    justify-self: end; */
}

.ownershipFilterOptions {
   color: #3e190f;
   font-size: 2rem;
   width: auto;
}

.ownershipFilterSwitch {
   display: flex;
   flex-direction: row;
   align-self: center;
}

.tokenRenderWrapper {
   /* border: 2px solid magenta; */
   display: flex;
   justify-content: center;
   justify-items: center;
   
   margin: 2% 4% 2% 4%;
}

.tokenRender {
/*    border: 2px solid white; */
   display: flex;
   justify-content: center;
   width: 100%;
}

.listItems {
   width: 100%;
/*    border: 2px solid teal; */
}


.cards {
   border-radius: 10;
}


.cardHeaders {
   color: white;
   background-color: #3e190f;
   font-size: 1.5rem;
   height: auto;
   display: flex;
/*    border: 1px yellow solid; */
   justify-content: center;
/*    align-content: center;
   align-self: center;
   align-items: center; */

/*  */
}


.nftImage {
/*    border: .25rem solid purple; */
   border-top: 2px solid black;
   border-bottom: 2px solid black;
   width: 100%;
   margin: 0;
   padding: 0;
}

.cardFooters {
   background-color: #3e190f;
   margin: 0;
   padding: 0
   /* border: 2px solid blue; */
}

.activityWrapper {
   /*    border: 2px solid red; */
      margin-bottom: 0;
      padding-bottom: 0;
   }

.listingStatusManager {
   display: grid;
   color: white;
   background-color: #3e190f;
   font-weight: bold;
   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr;
/*    border: 2px solid yellow; */
   font-size: 1rem;
}

.listingOwner {
   display: grid;
   grid-row: 1 / 2;
   grid-column: 1 / 2;
/*    border: 1px solid red; */
}

.listingStatus {
   display: grid;
   grid-row: 1 / 2 ;
   grid-column: 2 / 3;
/*    border: 1px solid red; */
/*    color: #F0F66E; */
}

.listingPrice {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 1 / 2;
/*    border: 1px solid red; */
/*    color: #A8C686; */
}

.listingFindersFee {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 2 / 3;
/*    border: 1px solid red; */
/*    color: #FF4F75; */  
}

.approvals_and_functions_wrapper {
   
   /* not in use but used for naming purposes*/
   /*    border: 2px solid red; */
}

.popoverMaster {
   display: grid;
   grid-row: 1 / 2;
   grid-column: 1 / 2;
  /*  justify-self: start; */
}

.approvalPopOverManager {
   display: grid;
/*    border: 1px white solid; */
   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   margin-left: 5%;
   margin-right: 5%;
}

.pleaseApprovePopover {
   display: grid;
   grid-column: 1 / 3;
   font-size: 1.5rem;
   text-align: center;
/*    border: 1px red solid; */
}

.erc721ApprovalButtonPopover {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 1 / 2;
   align-self: center;
   margin-left: 40%;
   margin-right: 2.5%;
}

.erc721ApprovedPopover {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 1 / 2;
   /* border: 2px magenta solid; */
   color: #A8C686;
   font-size: 1.5rem;
   align-items: center;
   justify-self: end;
   margin-right: 2.5%;
}

.zmmApprovalButtonPopover {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 2 / 3;
   align-self: center;
   font-size: 1.5rem;
   margin-left: 2.5%;
   margin-right: 40%;
}

.zmmApprovedPopover {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 2 / 3;
   /* border: 2px magenta solid; */
   color: #A8C686;
   font-size: 1.5rem;
   align-items: center;
   justify-self: start;
   margin-left: 2.5%;
}


.approvalManager {
   display: grid;
   grid-template-rows: 1fr;
   grid-template-columns: 1fr 1fr;
   height: auto;
/*    border: 1px white solid; */
   justify-items: space-evenly;
}

.pleaseApprove {
   display: grid;
   grid-row: 1 / 2;
   grid-column: 1 / 3;
   font-size: 2rem;
   justify-self: center;
}

.erc721ApprovalButton {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 1 / 2;
   font-size: 1.5rem;
   align-self: center;
   margin-left: 40%;
   margin-right: 2.5%;
}

.erc721Approved {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 1 / 2;
   /* border: 2px magenta solid; */
   color: #A8C686;
   font-size: 1.5rem;
   align-items: center;
   justify-self: end;
   margin-right: 2.5%;
}

.zmmApprovalButton {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 2 / 3;
   align-self: center;
   font-size: 1.5rem;
   margin-left: 2.5%;
   margin-right: 40%;
}

.zmmApproved {
   display: grid;
   grid-row: 2 / 3;
   grid-column: 2 / 3;
   /* border: 2px magenta solid; */
   color: #A8C686;
   font-size: 1.5rem;
   align-items: center;
   justify-self: start;
   margin-left: 2.5%;
}

.marketplaceManager {
   display: grid;
   background-color: #3e190f;
   width: 97%;
   margin-top: 7px;
   padding-bottom: 1px;
   grid-template: 1fr 1fr / 1fr 1fr;
   gap: 7px;
/*    border: 2px solid pink; */
}

.createAskFormPopoverManager {
   display: grid;
   grid-template: 1fr 1fr 1fr / 1fr ;
   margin-left: 10%;
   margin-right: 10%;
}

.updateAskFormPopoverManager {
   display: grid;
   grid-template: 1fr 1fr / 1fr;
   margin-left: 10%;
   margin-right: 10%;
}

.cancelAskFormPopoverManager {
   display: grid;
   grid-template: 1fr / 1fr;
   margin-left: 10%;
   margin-right: 10%;
}

.filllAskFormPopoverManager {
   display: grid;
   grid-template: 1fr 1fr / 1fr;
   margin-left: 10%;
   margin-right: 10%;
}

.marketplaceActionButtons {
   border: 1px solid #0000ff;
   font-size: 1.2rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}












