.aboutPageWrapper {
   background-color: transparent;
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   margin: 0;
   padding: 0;
   min-height: 800; 
}

.aboutPage {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   color: #3e190f;
}

.sections {
   width: 100%;
   margin-left: 15%;
   margin-right: 10%;
   text-align: start;
   margin-bottom: 2%;
}

.aboutPageHeaders {
   font-size: 2rem;
   font-weight: bold;

}

.aboutPageBody {
   font-size: 1.5rem;
   color: #3e190f;
}