:root {
  --header-grid-template: .5fr 1fr / 1fr 1fr; 
  --header-menu-grid-row: 1 / 3;
  --networkAndAccountWrapper-grid-row: 1 / 3;
  --networkAndAccountWrapper-grid-column: 2 / 3;
  --networkAndAccountWrapper-margin-bottom: 0;
}

@media (max-width: 860px) {
  :root {
  --header-grid-template: 1fr .5fr / 1fr; 
  --header-menu-grid-row: 2 / 3;
  --networkAndAccountWrapper-grid-row: 1 / 2;
  --networkAndAccountWrapper-grid-column: 1 / 2;
  --networkAndAccountWrapper-margin-bottom: 10px;
  }
}

@font-face {
  font-family: oliver-regular;
  src: url("./views/fonts/other_fonts/Oliver-Regular.ttf");
} 

html {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-image: linedPaperBackground;
  height: 100%;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: oliver-regular;
}

.OldEnglishWrapper {
  display: flex;
  justify-content: center;
  /* border: 3px solid teal; */
  width: 100%;
  margin: 0;
}

.MintWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;;
}

.header {
  display: grid;
  grid-template: var(--header-grid-template);
  width: 100%;
  margin: 2% 4%;
}

.headerMenu {
  grid-row: var(--header-menu-grid-row);
  grid-column: 1 / 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
}


.networkAndAccountWrapper {
    grid-row: var(--networkAndAccountWrapper-grid-row);
    grid-column: var(--networkAndAccountWrapper-grid-column);
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    margin-bottom: var(--networkAndAccountWrapper-margin-bottom);
    text-align: end;
}



.headerNetworkDisplay {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  font-size: 1.25rem;
}

.headerAccount {
  display: grid;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.menuButtonsMarketplace {
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #ffb300;
  text-align: center;
  width: 175px;
}

.marketplaceButton {
  background: url("./views/buttons/marketplaceButton_V2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
  width: 100%;
  color: transparent;

}

.mintButton {
  background: url("./views/buttons/mintButton_V2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
  width: 100%;
  color: transparent;
}

.aboutButton {
  background: url("./views/buttons/aboutButton_V2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
  width: 100%;
  color: transparent;
}

.menuButtonsMint {
  border: 2px solid #a12f99;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #ff95ff;
  width: 175px;
  text-align: center;
}

.menuButtonsAbout {
  border: 2px solid #005d8e;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #79b2ce;
  width: 175px;
  text-align: center;
}


